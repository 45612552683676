import React from 'react'
import './footer.scss'
import { Facebook, Instagram, Youtube, Telegram, Logo, Sponsor } from './assets/icons'
import Rec from './assets/Rec.png'
const Footer = () => {
  return (
    <div className='footer'>
        <div className="container">
            <div className="left-side">
                <div className="top">
                    <Logo/>
                    <span>Copyright Ⓒ 2022 Lingvela LLC <br />
All rights reserved</span>
                </div>
                <div className="middle">
                    <span>Toshkent shahar / 100084 <br />
Qoratosh, A.Xojayev 2 ko’chasi</span>
<ul>
    <li><a href="#"><Facebook/></a></li>
    <li><a href="#"><Instagram/></a></li>
    <li><a href="#"><Telegram/></a></li>
    <li><a href="#"><Youtube/></a></li>
</ul>
                </div>
                <img src={Rec} alt="" />
                <div className='sponsor'>
                <Sponsor/>
                </div>
            </div>

            <div className="right-side">
                <span>Formani to’ldiring, muxaxassislardan BEPUL
konsultatsiya oling va Chegirmaga ega bo’ling</span>
                <form action="">
                    <input type="text" name="" id="" />
                    <input type="text" name="" id="" />
                    <button>Konsultatsiya olish</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Footer
