/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.scss";
import {
  ArrowOne,
  ArrowTwo,
  Logo,
  Arrow,
  One1,
  Two1,
  Three1,
  TrueBlue,
  TrueWhite,
} from "./assets/icons";
import Y from "./assets/Youtube.svg";
import T from "./assets/Telegram.svg";
import I from "./assets/Instagram.svg";
import F from "./assets/Facebook.svg";
import Image from "./assets/bg.png";
import Image1 from "./assets/bg1.png";
import Image2 from "./assets/bg2.png";
import Admin from "./assets/adminopa.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "swiper/css";
import { db1, db2, db3, db4, json1, plan1, plan2, plan3 } from "./assets/db";
import Note from "./assets/note.png";
import Qiz from "./assets/qiz.png";
import Bola from "./assets/bola.png";
import Image3 from "./assets/bg3.png";
import Footer from "./footer";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import Ptichka from './assets/Ptichka Icon - 2.svg'

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [isOpenThree, setIsOpenThree] = useState(false);
  const [isOpenFour, setIsOpenFour] = useState(false);

  const resetField = (status) => {
    setForm({
      name: "",
      phone: "",
      text: "",
    });

   setFormTwo({
      name: "",
      phone: "",
      lang: "",
    });

    setIsOpen(false);
    setIsOpenTwo(false);
    setIsOpenThree(false);
    if (status) {
      setIsOpenFour(true);
    }
  };

  const [form, setForm] = useState({
    name: "",
    phone: "",
    text: "",
  });

  const [formTwo, setFormTwo] = useState({
    name: "",
    phone: "",
    lang: "",
  });

  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    form_fullname: form.name,
    form_phonenumber: form.phone,
    form_text: form.text,
  });

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const askQues = (el) => {
    el.preventDefault();
    fetch("https://lingvela.store/form3/create", requestOptions)
      .then((response) => response.text())
      .then((result) => resetField(JSON.parse(result).form_status))
      .catch((error) => console.log("error", error));
  };

  



  let rawTwo = JSON.stringify({
    form_fullname: formTwo.name,
    form_phonenumber: formTwo.phone,
    form_button: formTwo.lang,
  });

  let requestOptionsTwo = {
    method: "POST",
    headers: myHeaders,
    body: rawTwo,
    redirect: "follow",
  };

  const formTwoFunc = (el) => {
    el.preventDefault();
    fetch("https://lingvela.store/form1/create", requestOptionsTwo)
      .then((response) => response.text())
      .then((result) => resetField(JSON.parse(result).form_status))
      .catch((error) => console.log("error", error));
  };

  const formThreeFunc = (el) => {
    el.preventDefault();
    fetch("https://lingvela.store/form2/create", requestOptionsTwo)
      .then((response) => response.text())
      .then((result) => resetField(JSON.parse(result).form_status))
      .catch((error) => console.log("error", error));
  };





  useEffect(() => {
    setTimeout(() => {
      setIsOpenThree(true);
    }, 60000);
  }, []);
  return (
    <div className="App">
      <div className="banner" style={{ backgroundImage: `url(${Image})` }}>
        <div className="container">
          <div className="head">
            <div className="logo">
              <Logo />
            </div>
            <div className="links">
              <ul>
                <li>
                  <a href="#about">kurs haqida</a>
                </li>
                <li>
                  <a href="#student">o'quvchilar</a>
                </li>
                <li>
                  <a href="#plan">tariflar</a>
                </li>
                <li>
                  <a href="#faq">FAQ</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="banner-main-text">
            <h1>Atigi 4 oyda SIZ</h1>
            <p>
              Express Online kursiga qatnashib kuratorlar va murabbiylar bilan
              bir jamoada NOLdan ingliz va rus tillarini tez va oson o’rganasiz
            </p>
            <span className="tag">
              Uydan chiqmasdan turib <b>ONLINE</b> o’rganing
            </span>
            <a href="#plan" className="univ-btn">
              kursga yozilish
            </a>
          </div>

          <span className="bottom-tag">
            yangi kurs ochilishiga start berilish kuni:{" "}
            <b className="oth-text">7 kunu / 10:58 soat qoldi</b>
          </span>
        </div>
      </div>
      <div className="about-course" id="about">
        <div className="container">
          <div className="left-side">
            <h1>Kurs haqida</h1>
            <span className="tag">
              *muvaffaqiyat siri birinchi qadamni qo'yishdir
            </span>
            <p>
              Yevropa taʼlim texnologiyalar asosida, Yangi formatdagi til
              o‘rganish ONLINE MAKTABI ENDI Yurtimizda!
              <br />
              <br />
              Atigi 4 oyda SIZ Ingliz yoki rus tillarida Lingvela
              kompaniyasining 4 oylik Express Online kurslar orqali, 2 modul
              asosida va Mentor, Native hamda yordamchi kuratorlar asosida tez
              va oson o’z ona tilingiz kabi gapirasiz
            </p>
            <a href="#plan" className="univ-btn">
              ilk qadam qo’yish
            </a>
          </div>
          <div className="right-side">
            
            <iframe width="966" height="543" src="https://www.youtube.com/embed/9At6jsG5-0U" title="Yurtimizda ILK BOR! Online til Maktabi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div className="text">
              <h2>EXPRESS ONLINE KURS</h2>
              <span>haqida to’liq ma’lumot</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="who-is-teachers"
        style={{ backgroundImage: `url(${Image1})` }}
      >
        <div className="container">
          <div className="head">
            <h1 className="univ-text1">O’qituvchilar kim?</h1>
            <span className="tag">*professional asnosida!</span>
            <p>
              <b>Lingvela LLC</b> kompaniyasining o’qituvchilari Malakali bo’lib
              ko’p yillik tajribaga ega, o’z sohasining mutaxassislaridir
            </p>
          </div>
          {/* <Swiper navigation={true} modules={[Navigation]}>
            {[1, 1, 1, 1, 1].map((el) => {
              return (
                <SwiperSlide>
                  <div key={el} className="slider">
                    <img src={Admin} alt="" />
                    <div className="text">
                      <h2>RUSHANA SAFORALIYEVA</h2>
                      <span>Mentor o’qituvchi / Ikkinchi Toifali Pedagog</span>
                      <p>
                        Ko’p yillik tajribaga ega o’z sohasini mutahasisi kjh
                        dks; dhf;nsa; dknf; knas; lkdfl asf Ko’p yillik
                        tajribaga ega o’z sohasini mutahasisi kjhd
                        ks;dhf;nsa;dkn f;knas;lkdflasf
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper> */}
          <div className="teachers-card-wrapper">
            <div className="head">
              <h1>4 oylik kurs mobaynida 3ta o'qituvchi siz bilan ishlaydi.
</h1>
            </div>
            <div className="cards-wrapper">
              <div className="card">
                <img src={Ptichka} alt="" />
                <h3>Birinchisi 
Mentor o'qituvchi</h3>
                <p>Ushbu o’qituvchi 
o'quvchilarga birinchi 
3 oy davomida dars beradi
</p>
              </div>
              <div className="card">
                <img src={Ptichka} alt="" />
                <h3>Ikkinchi 
Yordamchi o'qituvchi</h3>
                <p>Yordamchi o'qituvchi 
o'quvchilarning uyga vazifalarini 
tekshiradi va o'quvchilarda savollar 
bo'lsa ish kunlari kun davomida 
online javob beradi!
</p>
              </div>
              <div className="card">
                <img src={Ptichka} alt="" />
                <h3>Uchunchi 
Native o'qituvchi</h3>
                <p>Ushbu o'qituvchi oxirgi 4 oyligida 
faqat chet tilida dars o'tadi va 
bu o'quvchini native spikkerlar kabi 
gapirishga to'liq yordam beradi</p>
              </div>
            </div>
          </div>

          <div className="button">
            <a href="#plan" className="univ-btn">
              kursga yozilish
            </a>
          </div>
        </div>
      </div>
      <div className="who-need-course">
        <div className="container">
          <h1 className="univ-text2">Bu kurs kim uchun?</h1>
          <span className="tag">*barchasi bugundan boshlanadi!</span>

          <div className="features">
            {json1.map((el, index) => {
              return (
                <div key={index} className="features-item">
                  <span>
                    <ArrowOne />
                  </span>
                  <p>{el.text}</p>
                </div>
              );
            })}
          </div>
          <div className="button">
            <a href="#plan" className="univ-btn">
              kursga qatnashmoqchiman
            </a>
          </div>
        </div>
      </div>
      <div
        className="waiting-course"
        style={{ backgroundImage: `url(${Image2})` }}
      >
        <div className="container">
          <h1 className="univ-text1">4 oylik kursda sizni kutiladi</h1>
          <span className="tag">
            *bugungi kundagi mutaxassis bir paytlar boshlang'ich edi
          </span>
          <div className="features">
            <div className="item">
              <span>
                <ArrowTwo />
              </span>
              <p>44 dars</p>
            </div>
            <div className="item">
              <span>
                <ArrowTwo />
              </span>
              <p>
                <b>Atmosfera</b> <br /> kurs jarayonlarni katta komanda bilan
                o’rganasiz
              </p>
            </div>
            <div className="item">
              <span>
                <ArrowTwo />
              </span>
              <p>
                <b>Platforma</b> <br />
                qulay platformada kurs jarayonlarni olib borasiz
              </p>
            </div>
          </div>
          <div className="square">
            <div className="head">
              <h1>Kurs afzalliklari</h1>
              <Arrow />
            </div>
            <div className="features-items">
              {db1.map((el, index) => {
                return (
                  <div key={index} className="item">
                    {el.icon}
                    <h3>{el.name}</h3>
                    <span>{el.text}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="button">
            <a href="#plan" className="univ-btn">
              darxol kursga yozilaman
            </a>
          </div>
        </div>
      </div>
      <div className="online-course">
        <div className="container">
          <h1 className="univ-text2">Online Express kursda siz</h1>
          <span className="tag">
            *aql va xarakter - bu haqiqiy ta'limning maqsadlari
          </span>

          <div className="course-details">
            <ul>
              <li>KURS DAVOMIYLIGI:</li>
              <li> 4 OY</li>
            </ul>
            <ul>
              <li>KURS BO’LIMLARI: </li>
              <li>2 MODUL</li>
            </ul>
            <ul>
              <li>KURS DARSLIKLARI:</li>
              <li> 44 DARS + 16 ONLINE BONUS DARS</li>
            </ul>
          </div>
          <div className="course-fea">
            {db2.map((el, index) => {
              return (
                <div key={index} className="cards">
                  <span>
                    <ArrowTwo />
                  </span>
                  <h3>{el.name}</h3>
                  <p>{el.text}</p>
                </div>
              );
            })}
          </div>
          <div className="conversation">
            <div className="head">
              <h2>
                <b>Har bir topshiriq shaxsan</b>
                <span>kurator tomonidan tekshiriladi</span>
              </h2>
            </div>
            <div className="conversation-human">
              <img src={Bola} alt="" />
              <div className="note">
                <span>Ravshan Avazov</span>
                <span>
                  Assalomu Alaykum, Ustoz! 👋 Uyga vazifalarni qilgandim. <br />
                  <br />
                  Ko’rib berasizmi? 🙏
                </span>
                <img src={Note} alt="" />
              </div>
            </div>
            <div className="conversation-human">
              <div className="qiz">
                <span>Kurator Mohinur</span>
                <span>Zo’r) shu tariqa davom eting 👍</span>
              </div>
              <img src={Qiz} alt="" />
            </div>
            <div className="conversation-human">
              <img src={Bola} alt="" />
              <div className="bola">
                <span>Ravshan Avazov</span>
                <span>Katta rahmat! Ustoz</span>
              </div>
            </div>
          </div>
          <div className="button">
            <a href="#plan" className="univ-btn">
              xoziroq kursga yozilaman
            </a>
          </div>
          <span className="span">
            yangi kurs ochilishiga start berilish kuni:{" "}
            <b> 7 kunu / 10:58 soat qoldi</b>
          </span>
        </div>
      </div>
      <div className="achievment" style={{ backgroundImage: `url(${Image3})` }}>
        <div className="container">
          <h1 className="univ-text1">Kursdan keyin nimaga ega bo’laman?</h1>
          <span className="tag">
            *chempionlar mukammallikka erishguncha ishlashda davom etadilar
          </span>
          <div className="numbers">
            {db3.map((el, index) => {
              return (
                <div key={index} className="card">
                  <span>{el.icon}</span>
                  <h3>{el.name}</h3>
                  <span>{el.text}</span>
                </div>
              );
            })}
          </div>
          <h1 className="univ-text1" id="student">
            O’quvchilarimizning <br />
            REAL fikrlari
          </h1>
          <span className="tag">*chempionlar shu yerda yaratiladi!</span>
          <div className="student-cards">
            {db4.map((el, index) => {
              return (
                <div key={index} className="card">
                  <div className="head">
                      <img src={el.image} alt="" />
                    <div className="name">
                      <span>{el.name}</span>
                      <span>{el.age}</span>
                    </div>
                  </div>
                  <p>{el.text}</p>
                </div>
              );
            })}
          </div>
          <div className="button">
            <a href="#plan" className="univ-btn">
              darxol kursga yozilaman
            </a>
          </div>
        </div>
      </div>

      <div className="how-enter">
        <div className="container">
          <h1 className="univ-text2">Kursga qanday yozilaman?</h1>
          <span className="tag">
            *bugun g'ayrioddiy narsa qilish uchun yana bir imkoniyat
          </span>

          <div className="steps">
            <h1>Oddiy 3 qadam</h1>
            <div className="step">
              <div className="step-numb">
                <One1 />
                <span>Kurs bo’yicha kerakli tarifni tanlang</span>
              </div>
              <div className="step-numb">
                <Two1 />
                <span>Formani to’ldiring</span>
              </div>
              <div className="step-numb">
                <Three1 />
                <span>Ertagayoq til o’rganishda birinchi qadamni qo’ying</span>
              </div>
            </div>
          </div>
          <div id="plan" className="plan">
            <div className="head">
              <h2>Qulay ta'lim formatini tanlang </h2>
              <h3>Maxsus taklif 24 soat amal qiladi!</h3>
            </div>
            <div className="plans">
              <div className="plan-one">
                
                <div className="head">
                  <span className="name">
                    <b>Start tarif</b> / mustaqil o’rganaman
                  </span>
                  <span className="price">
                    499 000 <span>so’m</span>
                  </span>
                  <span className="real-price" style={{color: '#000'}}>
                    Asl narx 1 200 000 <span>so’m</span>
                  </span>
                </div>
                <ul>
                  {plan1.map((el, index) => {
                    return (
                      <li key={index}>
                        <TrueBlue />
                        <span>{el.text}</span>
                      </li>
                    );
                  })}
                </ul>
                <div className="place">
                  <span>Joy qoldi: 300dan 75</span>
                  <button onClick={() => setIsOpen(true)} className="univ-btn">
                    kursga yozilaman
                  </button>
                </div>
              </div>
              <div className="plan-two">
                <div className="head">
                  <span className="name">
                    <b>Universal tarif</b> / kurator bn o’rganaman
                  </span>
                  <span className="price">
                    990 000 <span style={{color: '#fff'}}>so’m</span>
                  </span>
                  <span className="real-price">Asl narx 1 100 000 so’m</span>
                </div>
                <ul>
                  {plan2.map((el, index) => {
                    return (
                      <li key={index}>
                        <TrueWhite />
                        <span>{el.text}</span>
                      </li>
                    );
                  })}
                </ul>
                <div className="place">
                  <span>Joy qoldi: 200dan 36</span>
                  <button onClick={() => setIsOpen(true)} className="univ-btn">
                    kursga yozilaman
                  </button>
                </div>
              </div>
              <div className="plan-three">
                <div className="head">
                  <span className="name">
                    <b>Maksimal </b> tarif
                  </span>
                  <span className="price">
                    1 530 000 <span style={{color: '#fff'}}>so’m</span>
                  </span>
                  <span className="real-price">Asl narx 1 800 000 so’m</span>
                </div>
                <ul>
                  {plan3.map((el, index) => {
                    return (
                      <li key={index}>
                        <span className="icon">
                          <TrueBlue />
                        </span>
                        <span>{el.text}</span>
                      </li>
                    );
                  })}
                </ul>
                <div className="place">
                  <span>Joy qoldi: 10dan 2</span>
                  <button onClick={() => setIsOpen(true)} className="univ-btn">
                    kursga yozilaman
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="questions">
            <h1 className="univ-text2">Ko’p beriladigan savollar</h1>

            <div className="ask">
              <span className="quest">Savollaringiz qoldimi?</span>
              <button onClick={() => setIsOpenTwo(true)} className="univ-btn">
                Savol bermoq
              </button>
            </div>
            <ul>
              <li>
                <span>Kurs uchun to’lovni qanday amalga oshiraman</span>
                <span>
                  Istalgan to’lov turi orqali to’lovni amalga oshirib kursda
                  o’qishingiz mumkin Hammasi juda oddiy va qulaydir!
                </span>
              </li>
              <li>
                <span>Kursni boshlash uchun qanday ko'nikmalar kerak?</span>
                <span>
                  Biz siz bilan butunlay noldan boshlab ishlaymiz sizda kerakli
                  ko’nikmalar shart emas
                </span>
              </li>
              <li>
                <span>Men kurs yakunida sertifikat olamanmi?</span>
                <span>
                  Kursni tugatib, yakuniy ishni himoya qilganingizdan so'ng siz
                  maktabimizdan Albatta tugatganligingiz haqida Sertifikat
                  olasiz.
                </span>
              </li>
              <li>
                <span>Kuratorlar har bir talabani tekshiradimi?</span>
                <span>
                  Ha, albatta. Bizda bir nechta tajribali o'qituvchilar,
                  murabbiylar va kurs mualliflari bor, ular sizning uy
                  vazifangizni tekshirishga vaqt ajratadilar. Fikr-mulohaza
                  o'rganish nuqtai nazaridan bizning kuchli tomonlarimizdan
                  biridir.
                </span>
              </li>
              <li>
                <span>Uy vazifasini tekshirish qancha vaqt oladi?</span>
                <span>
                  1-3 ish kuni ichida sizga uy vazifangizning tahlilini
                  olishingiz kafolatlanadi.
                </span>
              </li>
            </ul>
          </div>
          <div className="about" id="faq">
            <h2>KOMPANIYA HAQIDA</h2>
            <p>
              Lingvela kompaniyasi 2018-yildan buyon xorijiy tillarni
              o`rganishga mo`ljallangan eng effektiv metodikalarni tahlil qilish
              va sotish bilan shug`ullanadi. Ushbu metodika AQSh va Yevropa
              ta'lim metodi va texnologialari asosida ishlab chiqilgan.
            </p>
          </div>
        </div>
      </div>
      <Footer />

      <Modal open={isOpen} footer={false} onCancel={() => setIsOpen(false)}>
        <div className="form-one">
          <span>Kursga yozilish</span>
          <h1>EXPRESS</h1>
          <span className="modal-tag">ONLINE KURSI</span>

          <div className="radio">
            <div className="radio-one">
              <input onChange={(el) => setFormTwo({...formTwo, lang: el.target.value})} type="radio" name="lang" id="one" value="English" />
              <label htmlFor="">Ingliz tili</label>
            </div>
            <div className="radio-one">
              <input onChange={(el) => setFormTwo({...formTwo, lang: el.target.value})} value="Russian" type="radio" name="lang" id="one" />
              <label htmlFor="">Rus tili</label>
            </div>
            <div className="radio-one">
              <input onChange={(el) => setFormTwo({...formTwo, lang: el.target.value})} value="Arab" type="radio" name="lang" id="one" />
              <label htmlFor="">Arab tili</label>
            </div>
          </div>
          <div className="inputs">
            <form action="">
              <input placeholder="Ism Familiya" type="text" name="" id="" 
                onChange={(el) => setFormTwo({...formTwo, name: el.target.value})}
              />
              <input
                placeholder="+998 99 999 99 99"
                type="number"
                name=""
                id=""
                onChange={(el) => setFormTwo({...formTwo, phone: el.target.value})}
              />
              <button onClick={formTwoFunc}>kursga yozilaman</button>
            </form>
          </div>
          <div className="agree">
            <input type="checkbox" name="" id="" checked />
            <label htmlFor="">
              Shaxsiy ma'lumotlarimni qayta ishlashga roziman
            </label>
          </div>
        </div>
      </Modal>

      <Modal
        open={isOpenTwo}
        footer={false}
        onCancel={() => setIsOpenTwo(false)}
      >
        <div className="form-one two" style={{ padding: "40px" }}>
          <h1
            style={{ fontSize: "30px", marginTop: "0",  }}
          >
            SAVOLINGIZNI QOLDIRING
          </h1>
          <span className="modal-tag">
            TEZ ORADA SIZ BILAN BOG’LANIB KOSULTATSIYA BERILADI!
          </span>

          <div className="radio">
            <textarea
              onChange={(el) => setForm({ ...form, text: el.target.value })}
              value={form.text}
              style={{ padding: "20px" }}
              name=""
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div className="inputs">
            <form action="">
              <input
                onChange={(el) => setForm({ ...form, name: el.target.value })}
                value={form.name}
                placeholder="Ism Familiya"
                type="text"
                name=""
                id=""
              />
              <input
                onChange={(el) => setForm({ ...form, phone: el.target.value })}
                value={form.phone}
                placeholder="+998 99 999 99 99"
                type="number"
                name=""
                id=""
              />
              <button onClick={(el) => askQues(el)}>kursga yozilaman</button>
            </form>
          </div>
          <div className="agree">
            <input type="checkbox" name="" id=""  checked/>
            <label htmlFor="">
              Shaxsiy ma'lumotlarimni qayta ishlashga roziman
            </label>
          </div>
        </div>
      </Modal>

      <Modal
        open={isOpenThree}
        footer={false}
        onCancel={() => setIsOpenThree(false)}
      >
        <div className="form-one">
          <span>Kursga yozilish</span>
          <h1 style={{ whiteSpace: "nowrap", fontSize: "30px" }}>
            MAXSUS TAKLIF
          </h1>
          <span className="modal-tag">
            KURSGA YOZILING VA <b style={{ color: "#168ADE" }}>15%</b>{" "}
            CHEGIRMAGA EGA BO’LING!
          </span>

          <div className="radio">
            <div className="radio-one">
              <input  onChange={(el) => setFormTwo({...formTwo, lang: el.target.value})} type="radio" name="lang" id="one" value="English" />
              <label htmlFor="">Ingliz tili</label>
            </div>
            <div className="radio-one">
              <input  onChange={(el) => setFormTwo({...formTwo, lang: el.target.value})} value="Russian" type="radio" name="lang" id="one" />
              <label htmlFor="">Rus tili</label>
            </div>
            <div className="radio-one">
              <input  onChange={(el) => setFormTwo({...formTwo, lang: el.target.value})} value="Arab" type="radio" name="lang" id="one" />
              <label htmlFor="">Arab tili</label>
            </div>
          </div>
          <div className="inputs">
            <form action="">
              <input onChange={(el) => setFormTwo({...formTwo, name: el.target.value})} placeholder="Ism Familiya" type="text" name="" id="" />
              <input
                placeholder="+998 99 999 99 99"
                type="number"
                name=""
                id=""
                onChange={(el) => setFormTwo({...formTwo, phone: el.target.value})}
              />
              <button onClick={formThreeFunc}>kursga yozilaman</button>
            </form>
          </div>
          <div className="agree">
            <input type="checkbox" name="" id=""  checked/>
            <label htmlFor="">
              Shaxsiy ma'lumotlarimni qayta ishlashga roziman
            </label>
          </div>
        </div>
      </Modal>
      <Modal
        open={isOpenFour}
        onCancel={() => setIsOpenFour(false)}
        footer={false}
      >
        <div className="result">
          <h1>TABRIKLAYMIZ</h1>
          <p className="one">SIZ FORMANI MUVAFFAQIYATLI TO’LDIRDINGIZ</p>
          <div className="color"></div>
          <p className="two">MUTAXASSISLAR SIZ BILAN TEZ ORADA BOG’LANADI</p>
          <div className="social">
            <p className="three">BIZNI IJTIMOIY TARMOQLARDA KUZATING</p>
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="#">
                  <img src={F} alt="" />
                </a>
              </li>

              <li>
                <a target="_blank" rel="noopener noreferrer" href="#">
                  <img src={I} alt="" />
                </a>
              </li>

              <li>
                <a target="_blank" rel="noopener noreferrer" href="#">
                  <img src={Y} alt="" />
                </a>
              </li>

              <li>
                <a target="_blank" rel="noopener noreferrer" href="#">
                  <img src={T} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;
