import { Chaqmoq, Degree, Five, Four, Like, Lines, One, Prize, Six, Three, Two, Users } from "./icons"
import Sar from './sarvar.png'
import Kam from './kamola.png'
import Azi from './35.png'
import Tem from './timur.png'
import Yus from './yusuf.png'
import Gul from './gulnoza.png'
export const json1 = [
    {
        text: 'Yillar davomida chet tilini o\’rganib hali hamon gapira olmayotganlar uchun'
    },
    {
        text: "Ko'p daromad olishni istaganlar uchun"
    },
    {
        text: "Rivojlanishni istaganlar uchun"
    },
    {
        text: "Yuqori lavozimga ko’tarilmayotganlar uchun"
    },
    {
        text: 'Chet elda ishlashni istaganlar uchun'
    },
    {
        text: 'Xorijiy TOP besseler kitoblarni o’qishda qiynalayotganlar uchun'
    },
    {
        text: 'Biznesi bor va hamkorlar bilan muzokarada chet tilida fikrini tushuntirishda qiynalayotganlar uchun'
    },
    {
        text: 'Til o‘rganish davomida qiyinchiliklarga uchrab, umidsizlikga tushayotganlar uchun'
    },
]

export const db1 = [
    {
        name: "Traning",
        text: `kurs davomida doimiy
        qo’shimcha treninglar
        bo’lib o’tadi`,
        icon: <Chaqmoq/>
    },
    {
        name: "Istiqbolli martaba rivojlanishi",
        text: `kurs jarayonida bilimlaringiz bilan birgalikda
        statusga ham ega bo’lasiz`,
        icon: <Degree/>
    },
    {
        name: "Konkurslar",
        text: `kurs jarayonida qimmatbaho
        sovg’alar o’ynaladi`,
        icon: <Prize/>
    },
    {
        name: "Malakali O’qituvchilar",
        text: `kurs jarayonini malakali
        murabbiylar va kuratorlar 
        olib boradi`,
        icon: <Users/>
    },
    {
        name: "Zamonaviy bilimlar",
        text: `kursda zamonaviy
        texnologiyalarga asoslangan
        bilimlar beriladi`,
        icon: <Like/>
    },{
        name: "Amaliy jarayonlar",
        text: `kursda nafaqat nazariy bilimlar
        balki amaliy jarayonlar ham
        mavjuddir`,
        icon: <Lines/>
    }
]

export const db2 = [
    {
        name: `5 MAHORAT`,
        text: `eshitish, gapirish, o’qish, 
        yozish va gramatika orqali`
    },
    {
        name: `ISTALGAN JOYDA VA VAQTDA`,
        text: `uydan chiqmasdan turib
        onlayn tarzda`
    },
    {
        name: `120 KUN`,
        text: `butun kurs mobaynida har kuni
        tabiiy materiallar joylashtiriladi`
    },
    {
        name: `3 O’QITUVCHI KO’MAGIDA`,
        text: `native o’qituvchi, malakali va yordamchi o’qituvchi ko’magida`
    },
    {
        name: `INDIVIDUAL TARZDA`,
        text: `o’quvchi bilan individual 
        yondashiladi`
    },
    {
        name: `NOLDAN BOSHLAB`,
        text: `hech qanday bilim baza bo’lishi 
        shart emas “o”dan o’rgana olasiz`
    }
]

export const db3 = [
    {
        icon: <One/>,
        name: `Erkin gapirasiz`,
        text: `4 oydan so’ng ingliz yoki rus tillarida erkin gapirasiz`
    },
    {
        icon: <Two/>,
        name: `Lavozim ko’tarilishi`,
        text: `chet tilini bilganingiz hisobiga
        lavozimingiz oshadi`
    },
    {
        icon: <Three/>,
        name: `Darajaning yuksalishi`,
        text: `Chet tilini bilganingiz uchun
        do’stlar ortirasiz va do’stalingiz oldida darajangiz oshadi`
    },
    {
        icon: <Four/>,
        name: `Oylik daromat oshishi`,
        text: `yuqori daromadli ishda ishlash imkoniyati`
    },
    {
        icon: <Five/>,
        name: `Dunyo darvozalari ochiq`,
        text: `Chet davlatlarida ishlash yoki o’qish imkoni`
    },
    {
        icon: <Six/>,
        name: `Erkin sayohat qilish`,
        text: `Chet tilini bilganingiz tufayli
        erkin sayohat qila olasiz`
    },
]


export const db4 = [
    {
        image: Sar,
        name: 'Sarvar',
        age: '16 yosh',
        text: `Assalomu Alaykum! Mening ismim Sarver yoshim 16da ingliz tilida gapirishda juda qiynalardim, 3 yildan beri tilning gramatikasini o’rganib kelgandim lekin natija umuman yo’q edi yanvar oyida Lingvela 4 oylik Express online kursini xarid qilgandim. Natijalarim juda yaxshi, xozirda chet elga o’qishga kirayabman. Lingvela jamoasiga katta raxmat aytaman.`
    },
    {
        image: Kam,
        name: 'Kamola',
        age: '20 yosh',
        text: `Assalomu Alaykum! Man Kamolaman Toshkent viloyatining Parkent tumanida istiqomat qilaman. Xozirda Jahon tillarida talabaman. Yaqinda Lingvela kompaniyasining Online Ingliz tili kurslarinini bitirdim. Oldin ingliz tilida yaxshi gapira olmasdim, lekin kursni bitirganimdan keyin bemalol gapira olayaman. Juda yaxshi keyslar berdi. Hammaga tavsiya qilaman.`
    },
    {
        image: Azi,
        name: 'Aziz',
        age: '35 yosh',
        text: `Assalomu alaykum! Mani Ismim Aziz Yoshim 35da Samarqandlikman. Yevropaga ishga ketish maqsadida ingliz tilini o’rganishga to’g’ri kelgandi, yaqin do’stim Lingvela kompaniyasida o’qishni tavsiya qildi o’qishga qaror qilgandim, mana xozir Angliyadan turib yozyabman Ingliz tilida bemalol gapira olaman hammga tavsiya beraman, juda zo’r narsalar o’rgatarkan`
    },
    {
        image: Tem,
        name: 'Timur',
        age: '22 yosh',
        text: `Salom! Man Timur, Jizzah viloyatidanman, Toshkent shahrida Institutda 4 kurs talabasiman. Keyingi yil Magistraturaga topshirish niyatida ingliz tilini o’rganishga qaror qabul qilgandim. bir do’stim Lingvela kompaniyasini o’quv kurslarini tavsiya qilgandi. Xozirda shu onlayn kursida 2 oydan beri o’qayabman, natijalrim yomonmas. barcha yurtdoshlarimga tavsiya beraman.`
    },
    {
        image: Yus,
        name: 'Yusuf',
        age: '25 yosh',
        text: `Assalom! Man Yusufman. Qo’qonlikman. rus tilida gapirishni orzu qilardim va nechchi yildirki gapirishni istardim, fevralda internetdan Lingvela Online kurslarini ko’rib qoldim va o’qishga qaror qildim. 3 oy oldin kursni bitiridim, xozir rus tilida bemalol gapiryabman. Lingvela jamoasiga katta raxmatlar aytaman orzuyim amalga oshdi, hammaga tavsiya beraman`
    },
    {
        image: Gul,
        name: 'Gulnoza',
        age: '30 yosh',
        text: `Assalomu alaykum! Ismim Gulnoza, Xorazmdanman. Ishim tufayli ko’p Rossiya bilan ishlayman lekin rossa gapirishda qiynalardim. o’tgan yili Lingvela Online kurslarida o’qidim, natijalarim judayam yaxshi. Barchaga tavsiya beraman, kompaniya hodimlariga raxmat!`
    },

]

export const plan1 = [
    {
        text: 'Kurs davomiyligi - 4 oy'
    },
    {
        text: '2 ta modul asosida'
    },

    {
        text: '44 ta dars'
    },
    {
        text: 'Uyga vazifalar sistemasi'
    },
    {
        text: 'Kurs yakunida Sertifikat'
    },
    {
        text: 'Treninglarga bilet 20% chegirmada'
    },
    {
        text: 'Umumiy Telegram chat'
    },
    {
        text: 'Native o’qituvchi darslari'
    },
    {
        text: 'Kursga kirish: 3 oy'
    },
]
export const plan2 = [
    {
        text: 'Kurs davomiyligi - 4 oy'
    },
    {
        text: '2 ta modul asosida'
    },

    {
        text: '44 ta dars'
    },
    {
        text: 'Uyga vazifalar sistemasi'
    },
    {
        text: 'Kurs yakunida Sertifikat'
    },
    {
        text: 'Treninglarga bilet 50% chegirmada'
    },
    {
        text: 'Umumiy Telegram chat'
    },
    {
        text: 'Native o’qituvchi darslari'
    },
    {
        text: 'Kursga kirish: 6 oy'
    },
    {
        text: 'Kurator biriktiriladi'
    },
    {
        text: 'Common Base BEPUL'
    },
    {
        text: 'Atmosphere Channel Media Bepul'
    },
    {
        text: 'Lingvela Covert Channel'
    },{
        text: `Kompaniya tomonidan 
        uyushtirilgan konkurslarga qatnashish imkoni`
    }
]

export const plan3 = [
    {
        text: 'Kurs davomiyligi - 4 oy'
    },
    {
        text: '2 ta modul asosida'
    },

    {
        text: '44 ta dars'
    },
    {
        text: 'Uyga vazifalar sistemasi'
    },
    {
        text: 'Kurs yakunida Sertifikat'
    },
    {
        text: 'Treninglarga bilet 50% chegirmada'
    },
    {
        text: 'Umumiy Telegram chat'
    },
    {
        text: 'Native o’qituvchi darslari'
    },
    {
        text: 'Kursga kirish: 1 yil'
    },
    {
        text: 'Kurator biriktiriladi'
    },
    {
        text: 'Common Base BEPUL'
    },
    {
        text: 'Atmosphere Channel Media Bepul'
    },
    {
        text: 'Lingvela Covert Channel'
    },{
        text: `Kompaniya tomonidan 
        uyushtirilgan konkurslarga qatnashish imkoni`
    },
    {
        text: `16 ta ONLINE dars`
    },
    {
        text: `Speaking Club`
    },
    {
        text: `Boshqa kurslarga
        20%lik chegirmali Vaucher`
    },
]